import { styled, Button } from 'shamrock-clover-ui';
import { useSearchCapacityQueryParams } from 'hooks/useQueryParams';

const StyledButton = styled(Button)`
  margin-top: 18px;
  white-space: nowrap;
  font-size: 16px; 
  font-weight: 400; 
`;

const ResetButton = ({onClick}: {onClick: () => void}) => {
  const {
    queryParams: {
      pickupCity,
      pickupState,
      deliveryCity,
      deliveryState,
      trailerType,
      deliveryDeadhead,
      pickupDeadhead,
    },
  } = useSearchCapacityQueryParams();

  return (
    <StyledButton
      width="81px"
      height="36px"
      isRounded={true}
      buttonStyle="outlined"
      disabled={
        !(
          pickupCity ||
          pickupState ||
          deliveryCity ||
          deliveryState ||
          trailerType ||
          deliveryDeadhead ||
          pickupDeadhead
        )
      }
      onClick={onClick}
      textTransform='capitalize'
    >
      Reset All
    </StyledButton>
  );
};

export default ResetButton;
