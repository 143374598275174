import { Container, GridContainer, GridItem } from 'features/ratePro/rateProV2/rateProStylesV2'

import SearchCapacityToolbar from './SearchCapacityToolbar'


const SearchCapacity = () => {


  return (
    <Container>
      <GridContainer container>
        <GridItem item xs={12}>
          <SearchCapacityToolbar />
        </GridItem>
      </GridContainer>
    </Container>
  )
}

export default SearchCapacity