import { Flex } from '../../../components/Flex';
import { LocationPicker } from './LocationPicker';
import { InputContainer, LabelText, StyledFormControl } from './PickerStyles';
import { RateProForm } from 'features/ratePro/RateProToolbar';

export enum PlaceTypes {
  POSTAL_CODE = 'postal_code',
  CITY_STATE = 'locality',
  ALL = 'all',
}

export type LocationPickerData = {
  city: string;
  state: string;
  postal_code: string;
};

type DeliveryPickerProps = {
  placeType: PlaceTypes;
  value: RateProForm;
  isDeliveryLocationSelected: boolean;
  updateDeliveryLocation: (
    city: string,
    state: string,
    postalCode?: string,
  ) => void;
};

export const NewDeliveryPicker = (props: DeliveryPickerProps) => {
  const { placeType, value, updateDeliveryLocation, isDeliveryLocationSelected } =
    props;
  const labelMap = {
    [PlaceTypes.POSTAL_CODE]: 'DELIVERY',
    [PlaceTypes.CITY_STATE]: 'DELIVERY',
    [PlaceTypes.ALL]: 'DELIVERY',
  };

  const label = labelMap[placeType];

  return (
    <Flex flexDirection="column" justifyContent="space-evenly">
      <LabelText>{label}</LabelText>
      <InputContainer>
        <StyledFormControl size="small">
          <LocationPicker
            placeHolderText={'City or Zip'}
            city={value.deliveryCity}
            state={value.deliveryState}
            postalCode={value.deliveryPostalCode}
            onChange={updateDeliveryLocation}
            placeType={placeType}
            inputWidth="150px"
            isLocationSelected={isDeliveryLocationSelected}
          />
        </StyledFormControl>
      </InputContainer>
    </Flex>
  );
};
